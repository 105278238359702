// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cheetah-admin-primary: mat-palette($mat-indigo);
$cheetah-admin-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cheetah-admin-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cheetah-admin-theme: mat-light-theme(
  (
    color: (
      primary: $cheetah-admin-primary,
      accent: $cheetah-admin-accent,
      warn: $cheetah-admin-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($cheetah-admin-theme);

/* You can add global styles to this file, and also import other style files */

// Google Fonts
// @import "fonts/googleOpenSans.scss";

// Variables, Mixins
@import "assets/theme/inspinia_v2.9.3/variables.scss";
@import "assets/theme/inspinia_v2.9.3/mixins.scss";
@import "assets/theme/inspinia_v2.9.3/variables_cheetah.scss";

//bootstrap
// @import 'assets/theme/bootstrap-sass/bootstrap';
@import "bootstrap/scss/bootstrap";

//font-awesome
// @import 'node_modules/@fortawesome/fontawesome-pro/scss/fontawesome';
// @import 'node_modules/@fortawesome/fontawesome-pro/scss/brands';
// @import 'node_modules/@fortawesome/fontawesome-pro/scss/regular';
// @import 'node_modules/@fortawesome/fontawesome-pro/scss/solid';
// @import 'node_modules/@fortawesome/fontawesome-pro/scss/v4-shims';

// INSPINIA Theme Elements
@import "assets/theme/inspinia_v2.9.3/typography.scss";
@import "assets/theme/inspinia_v2.9.3/navigation.scss";
@import "assets/theme/inspinia_v2.9.3/top_navigation.scss";
@import "assets/theme/inspinia_v2.9.3/buttons.scss";
@import "assets/theme/inspinia_v2.9.3/badges_labels.scss";
@import "assets/theme/inspinia_v2.9.3/elements.scss";
@import "assets/theme/inspinia_v2.9.3/sidebar.scss";
@import "assets/theme/inspinia_v2.9.3/base.scss";
@import "assets/theme/inspinia_v2.9.3/pages.scss";
@import "assets/theme/inspinia_v2.9.3/chat.scss";
@import "assets/theme/inspinia_v2.9.3/metismenu.scss";
@import "assets/theme/inspinia_v2.9.3/spinners.scss";

// datatables
// @import "datatables/_dataTables.bootstrap.scss";
// @import "datatables/_jquery.dataTables.scss";
// @import "datatables/custom.scss";

// Landing page
@import "assets/theme/inspinia_v2.9.3/landing.scss";

// RTL Support
@import "assets/theme/inspinia_v2.9.3/rtl.scss";

// For demo only - config box style
// @import "theme/_theme-config";

// INSPINIA Skins
@import "assets/theme/inspinia_v2.9.3/skins.scss";
@import "assets/theme/inspinia_v2.9.3/md-skin.scss";

// Media query style
@import "assets/theme/inspinia_v2.9.3/media.scss";

// Custom style
// Your custom style to override base style
@import "assets/theme/inspinia_v2.9.3/custom.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "assets/css/animate.css";

// plugins
// @import "plugins/awesome-bootstrap-checkbox.scss";
// @import "plugins/ui-select.scss";
// @import "plugins/dropzone.scss";
// @import "plugins/toastr/toastr.scss";
// @import "plugins/toastr/toastrCustom.scss";

// components
// @import "components/segment-filter.scss";
// @import "components/event-source.scss";
// @import "components/promotion-pod.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
